$bn_language: true;

// Body
$body-bg: #ffffff;

// Typography

$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$flagsImagePath: "../../build/img/" !default;
$font-family-sans-serif: 'Open Sans',sans-serif;

@if $bn_language {
    $font-family-sans-serif: 'SolaimanLipi', Arial, sans-serif;
}
